
























import {Component, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {mixins} from 'vue-class-component';
import NumbersMixin from '@/mixins/NumbersMixin';
import Paginate from 'vuejs-paginate';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {CashEarningInterface} from '@/types/CashEarningInterface';

@Component({components: {Paginate}})
export default class CashEarnings extends mixins(NumbersMixin, MomentDateMixin) {
  data: CashEarningInterface[] = [];

  /* pagination params */
  totalPages = 1;
  params: any = {
    page: 1,
    take: 10,
  };

  @Watch('params.page') onPageChange() {
    this.loadHistory();
  }

  get dateQueryString(): string {
    return `page=${this.params.page}&take=${this.params.take}`;
  }

  created() {
    this.loadHistory();
  }

  loadHistory() {
    vxm.model
      .getEarnings(this.dateQueryString)
      .then((res) => {
        this.totalPages = Math.ceil(res.data.count / this.params.take);
        this.data = res.data.items;
      })
      .catch((error) => {
        return error;
      });
  }
}
